import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import auto_collector from "../../public/static/images/products/auto_collector.jpg"

function AutoCollector() {

  const prodDetail = {
    "name": "Auto Collector / Tipper",
    "detail": {
      "advantages" : ["This number of trips can be increased.", "It is totally hygienic and thus, much safer.", "It can easily move in narrow lanes.", "It provides company covered transportation.", "Multiple handling is not required.", "The segregation of garbage can take place at the source i.e dry/wet, organic /inorganic."],
      "about" : ["Auto collector is a unique innovation to facilitate door to door collection of garbage and its proper disposal."],
      "Salient Features": [
        "This equipments can dump the garbage on the ground, as well as director into a collector and also in the refuse collector.",
        "The capacity of the auto remover is 1.0 cubic meters volumetric capacity hopper.",
        "The dumping height from the ground is 1000mm / 120mm."
      ],
      "Features": [
        "One system can easily handle lifting, shifting and dumping 10/12 containers during working hours.",
        "It facilities in safe and hygienic collection, storage and large volume of garbage transportation with fast operation."
      ]
    },
    "tech_data": {
      "column1": [
        "Capacity",
        "Bottom",
        "Side / Top",
        "Door",
        "Tailgate"
      ],
      "column2": [
        "1.2 / 2 / 4m³",
        "14 GMS Sheet",
        "16 GMS Sheet",
        "18 GMS Sheet",
        "Hinged Type with Locking Arrangement"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={auto_collector} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default AutoCollector
